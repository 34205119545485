import React from "react"
import Layout from "../components/Layout"
import Countdown from "react-countdown"

const Error = props => {
  const Completionist = () => <span>0</span>
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />
    } else {
      // Render a countdown
      return <span>{seconds}</span>
    }
  }
  return (
    <Layout>
      <meta http-equiv="refresh" content="6; url = https://www.krametter.at" />
      <main>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "30px",
          }}
        >
          <div>
            <p style={{ fontSize: "2rem", textAlign: "center" }}>Fehler 404</p>
            <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
              Diese Seite existiert nicht.
            </p>
            <p style={{ fontSize: "1.5rem", textAlign: "center" }}>
              Automatische Weiterleitung in{" "}
              <b>
                <Countdown date={Date.now() + 5000} renderer={renderer} />
              </b>{" "}
              Sekunden.{" "}
            </p>
          </div>
        </div>

        <div style={{ height: "70vh" }}></div>
      </main>
    </Layout>
  )
}

export default Error
